<template>
  <div></div>
</template>

<script>
export default {
  name: 'HomePage',
  head() {
    return {
      title: 'Digital Link Landings',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: '',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Digital Link Landings',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: '',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Digital Link Landings',
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://cdn.digital-link.com/meta-blank-v2.png',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.domain,
        },
        {
          hid: 'twitter:card',
          property: 'twitter:card',
          content: 'https://cdn.digital-link.com/meta-blank-v2.png',
        },
        { name: 'robots', content: 'index,follow' },
      ],
    }
  }
  
}
</script>
